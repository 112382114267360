@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
* {
  scrollbar-color: rgba(44, 174, 226, 1) #f0f0f0;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}
::-webkit-scrollbar-thumb {
  background: rgba(44, 174, 226, 1);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
::-webkit-scrollbar-track {
  background: #f0f0f0;
}
